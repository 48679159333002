import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

import Router from "./router";
import i18n from "./translation";

const App = () => {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyBHDOiuCsa_jYZNckAtsyLbT_dG9AOZrJo",
    authDomain: "developx-web.firebaseapp.com",
    projectId: "developx-web",
    storageBucket: "developx-web.appspot.com",
    messagingSenderId: "881852388900",
    appId: "1:881852388900:web:ef80945ea146ae33f9aab1",
    measurementId: "G-R3QRD4QDKR"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  // Track home page visit
  logEvent(analytics, 'homepage_view');

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Router />
      </I18nextProvider>
    </BrowserRouter>
  )
}

ReactDOM.render(<App />, document.getElementById("root"));
